const SlugifyUtils = () => {
  const generateSlug = (text) => {
    const slug = text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/\./g, '-') // Replace dots with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text

    return slug
  }

  return generateSlug
}

export default SlugifyUtils
